<div class="construction-page background-primary">
    <div class="container">
        <div class="construction-headline">
            <h1 class="font-color-primary">Andrea Singer</h1>
            <h2 class="font-color-secondary">Doula & Geburtsbegleitung in München</h2>
        </div>

        <div class="construction-contact">
            <app-contact></app-contact>
        </div>
    </div>
</div>
