<img class="main-image" src="../../assets/view4.jpg"/>

<div class="container">
    <div class="spacer-small">
        <p>„Eine Geburt gehört zu den aufregendsten Ereignissen in Ihrem Leben!“ Dieser weitverbreitete Satz weckt hohe Erwartungen.</p>
        <p>Doch wie geht es weiter, wenn man sich in diesem idyllischen Bild nicht wiederfindet, wenn die Geburt gar wie „ein Albtraum“ erlebt wurde? Das Geburtserleben kann nachhaltig weiterwirken und dabei Ängste und schwierige Emotionen mit sich bringen. Sie fragen sich vielleicht, was sie falsch gemacht haben oder ob sie mit ihrem Empfinden alleine sind. Möglicherweise wirkt sich das Erlebte auf ihr Muttersein und die Beziehung zu ihrem Baby aus. Vielleicht funktionieren sie im Alltag gut, müssen dafür aber viel Kraft aufwenden. In dieser Situation kann es hilfreich sein, den Prozess der Geburt in seiner Gänze von oben zu betrachten, ähnlich, wie bei einer Ballonfahrt. Bei blauem Himmel und mit einem erfahrenen Ballonführer sehen sie sich die Gegend von oben an.</p>
        <p>Der so gewonnene Überblick, kann wieder freier machen, verschüttete Qualitäten und Ressourcen zu entdecken und allmählich Selbstvertrauen zurück zu gewinnen. Sie können ein paar Sandsäckchen abwerfen und sich entlasten. Schwieriges Erleben rund um die Geburt, während der Geburt und in der Gegenwart kann in der Beratung dynamisch bearbeitet und verändert werden. Mit meinem Handwerkskoffer als Traumafachberaterin (THZM) und vielen anderen professionellen Methoden können wir sortieren, trennen und den Fokus auf Heilendes und Hilfreiches lenken.</p>
    </div>
</div>

<div class="spacer"></div>
<app-podcast></app-podcast>

<div class="spacer"></div>
<div class="container">
    <app-biography></app-biography>
</div>

<div class="spacer"></div>
<app-education></app-education>

<div class="container">

    <div class="spacer"></div>
    <app-angebot></app-angebot>

    <div class="spacer"></div>
    <app-contact></app-contact>

</div>
