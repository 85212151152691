import { Component, OnInit } from '@angular/core';
import { faArrowUp, faCoffee, faEnvelope, faMailBulk, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  phoneIcon = faPhone;
  faEnvelope = faEnvelope;

  faArrowTop = faArrowUp;

  constructor() { }

  ngOnInit(): void {
  }

  scrollToTop() {
    this.scrollToSection('header');
  }

  scrollToSection1() {
    this.scrollToSection('section1');
  }

  scrollToSection2() {
    this.scrollToSection('section2');
  }

  scrollToSection3() {
    this.scrollToSection('section3');
  }

  scrollToSection(target) {
    console.log('scroll action', target);
    const elem = document.getElementById(target);

    if (!elem) {
      console.error('could not find element with id', elem);
    } else {
      elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  }

}
