<div id="header" class="header background-primary">
    <div class="scroll-to-top"
         (click)="scrollToTop()">
        <fa-icon [icon]="faArrowTop"></fa-icon>
    </div>

    <div class="container">
        <div class="header-content">
            <div class="header-title">
                <h1 class="font-color-primary">Andrea Singer</h1>
                <h2 class="font-color-secondary">Heilpraktikerin (Psych) – Coach für Schwangerschaft und Geburt - Doula</h2>
            </div>

            <!--
            <div class="header-navigation">
                <div class="header-navigation-item font-color-primary"
                     (click)="scrollToSection1()">Thema 1</div>

                <div class="header-navigation-item font-color-primary"
                     (click)="scrollToSection2()">Thema 2</div>

                <div class="header-navigation-item font-color-primary"
                     (click)="scrollToSection3()">Thema 3</div>
            </div>
            -->

        </div>
    </div>
</div>
