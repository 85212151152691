<div *ngIf="!underConstruction">
    <app-header></app-header>

    <router-outlet></router-outlet>

    <app-footer></app-footer>
</div>

<div *ngIf="underConstruction">
    <app-construction-page></app-construction-page>
</div>
