<div class="footer background-primary">

    <div class="container">
        <div class="footer-content">

            Copyright Andrea Singer 2020

            <div class="footer-link-section">
                <a class="footer-link" routerLink="impressum">Impressum</a>
                <a class="footer-link" routerLink="datenschutz">Datenschutz</a>
            </div>
        </div>

        <div class="git-version">
            {{timestamp}} - {{gitCommit}} - {{gitBranch}}
        </div>
    </div>

</div>
