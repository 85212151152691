<div class="biography">

    <div class="biography-image-content">
        <img class="biography-image" src="assets/andi.png"/>
        <div class="biography-content">
            <h1>Andrea Singer</h1>
            <h4>Heilpraktikerin (Psych) – Coach für Schwangerschaft und Geburt - Doula</h4>
            <p>Meine berufliche Leidenschaft dreht sich seit vielen Jahren um das Thema Frausein, Schwangerschaft und Geburt. Schon seit meinem Studium der Archäologie und Ethnologie beschäftigte ich mich am liebsten mit Frauen anderer Zeiten und Weltgegenden. Der rote Faden ist meine Frage: Welches Leben haben Frauen und Familien damals wie heute geführt? Wie haben sie geboren? Was, wenn es kein glückliches Ende gibt? Wie können sie Krisen überwinden? Was unterstützt Frauen in diesen existentiellen Erfahrungen wirklich?</p>
            <p>Dann habe ich diese Passion vor fast 20 Jahren zu meinem zweiten Beruf gemacht. Ich fokussiere mich darauf Ressourcen zu entdecken, die in jeder Frau von Natur aus da sind. Erfahrungen bei den Geburten meiner beiden Töchter haben mich angetrieben andere Frauen darin zu bestärken, ihre eigenen Kräfte zu entwickeln. Ich habe viel Zeit und Kraft in Ausbildungen investiert, die aus belastenden Geburtserfahrungen Nährstoff für die Zukunft werden lassen. Ich verfüge über langjährige praktische Erfahrung in Verbindung mit der Fülle meines professionellen Handwerkskoffers.</p>
            <p>Kernthemen wie „Schwere Geburten“ und Trauerbegleitung haben mich nicht mehr losgelassen. Ein Fundament aus ressourcenorientiertem Coaching, hypnosystemischen Methoden, traumasensiblen Techniken, Entspannungsverfahren und meine Interkulturelle Kompetenz nutze ich in meiner Praxis.</p>
        </div>
    </div>
</div>
