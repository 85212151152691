<div class="contact">
    <h3>Kontakt</h3>

    <div class="contact-list">
        <div class="contact-row">
            <fa-icon [icon]="phoneIcon"></fa-icon>
            <a href="tel:+491735995968">0173 / 599 59 68</a>
        </div>

        <div class="contact-row">
            <fa-icon [icon]="mailIcon"></fa-icon>
            <a href="mailto:info@andrea-singer.de">info@andrea-singer.de</a>
        </div>
    </div>
</div>
