<div class="imprint-page">
    <div class="container">
        <div class="spacer-small"></div>
        <a routerLink="">Zurück</a>
        <div class="spacer"></div>
        <h3>Verantwortlich gemäß §6MDSTV:</h3>
        <p>Andrea Singer</p>
        <p>Heilpraktikerin für Psychotherapie (HpG)</p>
        <p>0173 5995968</p>
        <p>Andrea Singer</p>
        <p>Oettingenstr. 52</p>
        <p>80538 München</p>
        <p>Email: info@andrea-singer.de</p>
        <p>Webside: www.andrea-singer.de</p>
    </div>
</div>
