import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-datenschutz-page',
    templateUrl: './datenschutz-page.component.html',
    styleUrls: ['./datenschutz-page.component.css']
})
export class DatenschutzPageComponent implements OnInit {

    constructor() {}

    ngOnInit() : void {
    }


}
