import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { BiographyComponent } from './biography/biography.component';
import { FooterComponent } from './footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContactComponent } from './contact/contact.component';
import { ConstructionPageComponent } from './construction-page/construction-page.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { MainPageComponent } from './main-page/main-page.component';
import { ImpressumPageComponent } from './impressum-page/impressum-page.component';
import { DatenschutzPageComponent } from './datenschutz-page/datenschutz-page.component';
import { EducationComponent } from './education/education.component';
import { AngebotComponent } from './angebot/angebot.component';
import { PodcastComponent } from './podcast/podcast.component';

const routes : Routes = [
    {
        path: '',
        component: MainPageComponent
    },
    {
        path: 'impressum',
        component: ImpressumPageComponent
    },
    {
        path: 'datenschutz',
        component: DatenschutzPageComponent
    }
];

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        BiographyComponent,
        FooterComponent,
        ContactComponent,
        ConstructionPageComponent,
        MainPageComponent,
        ImpressumPageComponent,
        DatenschutzPageComponent,
        EducationComponent,
        AngebotComponent,
        PodcastComponent
    ],
    imports: [
        BrowserModule,
        NgbModule,
        FontAwesomeModule,
        HttpClientModule,
        MarkdownModule.forRoot(),
        RouterModule.forRoot(routes)
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
