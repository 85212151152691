    <div class="background-primary">
<div class="container education">
        <div>
            <h4>Qualifikationen und Berufspraxis</h4>
            <ul>
                <li>Zweijährige Ausbildung zur Traumafachberaterin (THZ München)</li>
                <li>Psychosoziale Beraterin rund um pränatale Diagnostik , traumatischen Geburten und bei belasteten Schwangerschaft in einer Beratungsstelle</li>
                <li>Coaching-Ausbildung zum NLP Practicioner (DVNLP)</li>
                <li>Seit 2009 Trainerin der Weiterbildung GfG-Doula® in München</li>
                <li>Dreijähriges Studium der heilkundlichen Psychotherapie (TBA Bamberg) mit staatlicher Zulassung Gesundheitsamt München 2008</li>
                <li>19 Jahre Berufserfahrung als Kursleiterin für Geburtsvorbereitung und Doula in Kliniken, Geburtshäusern und zu Hause.</li>
                <li>Zweijährige Ausbildungen zur GfG-Geburtsvorbereiterin® und GfG-Doula® bei der Gesellschaft für Geburtsvorbereitung e.V.</li>
                <li>Abgeschlossenes Studium der Archäologie und Ethnologie (Magistra artium)</li>
            </ul>
        </div>
    </div>
</div>
