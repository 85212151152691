import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-biography',
    templateUrl: './biography.component.html',
    styleUrls: ['./biography.component.css']
})
export class BiographyComponent implements OnInit {

    constructor() {
    }

    ngOnInit() : void {
    }

}
