import { Component, OnInit } from '@angular/core';
import { gitVersion } from '../../environments/git-version';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    gitBranch;
    gitCommit;
    timestamp;

    constructor() {
        this.gitBranch = gitVersion.branch;
        this.gitCommit = gitVersion.commit;
        this.timestamp = gitVersion.timestamp;
    }

    ngOnInit() : void {
    }

}
